import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		token: '',
		name: '',
	},
	mutations: {
		setToken(state, data) {
			state.token = data
		},
		setName(state, data) {
			state.name = data
		}
	},
	actions: {
		setToken(context, data) {
			context.commit('setToken', data)
		},
		setName(context, data) {
			context.commit('setName', data)
		}
	}
})

export default store
