import Vue from 'vue'
import VueRouter from 'vue-router'

//修改原型对象中的push方法, 屏蔽原地跳转错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    meta: {
      title: '首页'
    },
    name: 'Index',
    component: () => import( '../components/Index.vue')
  },
  {
    path: '/AboutUs',
    meta: {
      title: '关于我们'
    },
    name: 'AboutUs',
    component: () => import( '../components/AboutUs.vue')
  },
  {
    path: '/Login',
    meta: {
      title: '用户登录'
    },
    name: 'Login',
    component: () => import( '../components/Login.vue')
  },
  {
    path: '/Regist',
    meta: {
      title: '用户注册'
    },
    name: 'Regist',
    component: () => import( '../components/Regist.vue')
  } ,
  {
    path: '/WuliuIndex',
    meta: {
      title: '物流服务'
    },
    name: 'WuliuIndex',
    component: () => import( '../components/WuliuIndex.vue')
  },
  {
    path: '/JinrongIndex',
    meta: {
      title: '金融服务'
    },
    name: 'JinrongIndex',
    component: () => import( '../components/JinrongIndex.vue')
  },
  {
    path: '/FalvIndex',
    meta: {
      title: '法律服务'
    },
    name: 'FalvIndex',
    component: () => import( '../components/FalvIndex.vue')
  },
  {
    path: '/Projects',
    meta: {
      title: '项目'
    },
    name: 'Projects',
    component: () => import( '../components/business/Projects')
  },
  {
    path: '/Transport',
    meta: {
      title: '运力'
    },
    name: 'Transport',
    component: () => import( '../components/business/Transport')
  },
  {
    path: '/Support',
    meta: {
      title: '配套设施'
    },
    name: 'Support',
    component: () => import( '../components/business/Support')
  },
  {
    path: '/Applyflow',
    meta: {
      title: '申请流程'
    },
    name: 'Applyflow',
    component: () => import( '../components/business/Applyflow')
  },{
    path: '/MyCenterInfo',
    meta: {
      title: '个人中心'
    },
    name: 'MyCenterInfo',
    component: () => import( '../components/business/MyCenterInfo')
  },
  {
    path: '/MyCenter',
    meta: {
      title: '个人中心'
    },
    name: 'MyCenter',
    component: () => import( '../components/common/center/myCenter'),
    children:[
      {
        path: 'confirmPay',
        name: 'confirmPay',
        props: true,
        meta: {
          title: '打款确认'
        },
        component: () => import('@/components/common/center/confirmPay')
      },
      {
        path: 'myApply',
        name: 'myApply',
        props: true,
        meta: {
          title: '我的申请'
        },
        component: () => import('@/components/common/center/myApply')
      },
      {
        path: 'myPush',
        name: 'myPush',
        props: true,
        meta: {
          title: '我的发布'
        },
        component: () => import('@/components/common/center/myPush')
      },
      {
        path: 'mySetting',
        name: 'mySetting',
        props: true,
        meta: {
          title: '账号设置'
        },
        component: () => import('@/components/common/center/mySetting')
      },
      {
        path: 'templateDown',
        name: 'templateDown',
        props: true,
        meta: {
          title: '模板下载'
        },
        component: () => import('@/components/common/center/templateDown')
      },
      {
        path: 'userDetail',
        name: 'userDetail',
        props: true,
        meta: {
          title: '个人信息'
        },
        component: () => import('@/components/common/center/userDetail')
      },
      {
        path: 'userOther',
        name: 'userOther',
        props: true,
        meta: {
          title: '更多信息'
        },
        component: () => import('@/components/common/center/userOther')
      },
      {
        path: 'userPolish',
        name: 'userPolish',
        props: true,
        meta: {
          title: '更新资料'
        },
        component: () => import('@/components/common/center/userPolish')
      },
      {
        path: 'financeIndex',
        name: 'financeIndex',
        props: true,
        meta: {
          title: '金融服务'
        },
        component: () => import('@/components/common/center/finance/FinanceIndex'),
        children:[
          {
            path: 'myApplyLog',
            name: 'myApplyLog',
            props: true,
            meta: {
              title: '我的申请'
            },
            component: () => import('@/components/common/center/finance/MyApplyLog'),
          }
        ]
      }
    ]
  },
]

const router = new VueRouter({
  /* mode: 'history', */
  base: '/',
  routes
})
export default router
