<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
	export default {
		created() {
			sessionStorage.getItem("userMsg") && this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(
				sessionStorage.getItem("userMsg"))));
			//在页面刷新时将vuex里的信息保存到sessionStorage里
			window.addEventListener("beforeunload", () => {
				sessionStorage.setItem("userMsg", JSON.stringify(this.$store.state))
			})
		}
	}
</script>
<style>
  html{
    font-size: 10px;
    font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  }
  body,html{
    padding: 0;
    margin: 0;
    height: 100vh;
  }
  body *{
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: #5c5c5c;
  }
  #app{
    padding: 0;
    height: 100%;
  }
</style>

