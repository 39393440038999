import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from "@/router";
import store from "./store";
import http from "./util/http.js";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/icon.css';
import BaiduMap from 'vue-baidu-map';


Vue.prototype.HTTP = http
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
});

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'XOEd8eClfQQVaskYcgHeKcRimErc1L4b'
})

router.beforeEach((to,form,next)=>{
	if(to.fullPath =='/ManageCenter'){
		window.open("http://www.yuntuzl.net/login");
	}else{
		next();
	}
	
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
